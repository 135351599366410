<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-50"> {{ title }} </b-card-title>
      <div class="ivu-col select-container">
        <div class="chart-select">
          <div class="tittle-pro">货币</div>
          <el-select
            v-model="currency"
            placeholder="BTC"
            @change="changeCurrency"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="chart-select">
          <div class="tittle-pro">交易对</div>
          <el-select
            v-model="tradingOn"
            placeholder="USD"
            @change="changeTradingOn"
          >
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="chart-select">
          <div class="tittle-pro">时间</div>
          <el-select
            v-model="timeSplit"
            placeholder="5分钟"
            @change="changeTime"
          >
            <template v-if="Bybit === 'Bybit'">
              <el-option
                v-for="item in options5"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </template>
            <template v-else>
              <el-option
                v-for="item in options4"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </template>
          </el-select>
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <div ref="Proportion" style="height: 300px"></div>
    </b-card-body>
  </b-card>
</template>
<script>
import * as echarts from "echarts";
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
  },
  props: {
    // 动态传递一个数据
    series: {
      type: Array,
    },
    // 动态传递一个title
    title: {
      type: String,
    },
    chartType: {
      type: Number,
      default: 1,
    },
    Bybit: {
      type: String,
    },
  },
  data() {
    return {
      //定义变量来装图表对象
      chart: null,
      options: [
        {
          value: "BTC", // 这个value 写BTC  对应上
          label: "BTC",
        },
        {
          value: "ETH",
          label: "ETH",
        },
        // {
        //   value: "EOS",
        //   label: "EOS",
        // },
        // {
        //   value: "BCH",
        //   label: "BCH",
        // },
        // {
        //   value: "LTC",
        //   label: "LTC",
        // },
        // {
        //   value: "XRP",
        //   label: "XRP",
        // },
      ],
      options2: [
        {
          value: "USD",
          label: "USD",
        },
        {
          value: "USDT",
          label: "USDT",
        },
      ],
      options3: [
        {
          value: "1",
          label: "bybit",
        },
        {
          value: "2",
          label: "Binance",
        },
        {
          value: "3",
          label: "Okex",
        },
        {
          value: "4",
          label: "Huobi",
        },
        {
          value: "5",
          label: "Bitmex",
        },
        {
          value: "6",
          label: "Deribit",
        },
      ],
      options4: [
        {
          value: "5m",
          label: "5分钟",
        },
        {
          value: "15m",
          label: "15分钟",
        },
        {
          value: "30m",
          label: "30分钟",
        },
        {
          value: "1h",
          label: "1小时",
        },
        {
          value: "2h",
          label: "2小时",
        },
        {
          value: "4h",
          label: "4小时",
        },
        {
          value: "6h",
          label: "6小时",
        },
        {
          value: "12h",
          label: "12小时",
        },
        {
          value: "1d",
          label: "1天",
        },
      ],
      options5: [
        {
          value: "5m",
          label: "5分钟",
        },
        {
          value: "15m",
          label: "15分钟",
        },
        {
          value: "30m",
          label: "30分钟",
        },
        {
          value: "1h",
          label: "1小时",
        },
        {
          value: "4h",
          label: "4小时",
        },
        {
          value: "1d",
          label: "1天",
        },
      ],
      currency: "BTC",
      tradingOn: "USD",
      timeSplit: "5m",
    };
  },
  mounted() {
    this.getEchartData();
    window.addEventListener("resize", this.chart.resize);
  },
  methods: {
    getEchartData() {
      //获取图表的DOM元素
      const chart = this.$refs.Proportion;

      //将初始化生成的图表对象放到变量
      this.chart = echarts.init(chart);
      // 定义配置项
      const option = {
        title: {
          // text: "Open Interest By Strike Price",
        },
        tooltip: {
          trigger: "axis",
          borderWidth: "0", //边框宽度设置1
          borderColor: "none", //设置边框颜色
          formatter: (params) => {
            let aaa = this.kmb(params[0].data);
            let bbb = this.kmb(params[1].data);
            return `
                <div>
                  <div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>
                  <div><span style="color:#46C195">${params[0].seriesName}:  ${aaa}</span><br/>
                   <span style="color:rgba(255, 82, 96, 1)">${params[1].seriesName}: ${bbb}</span><br/>
                  </div>
                 
                </div>
              `;
          },
          padding: 20,
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },
          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
        },
        legend: {},
        xAxis: {
          data: [],
        },
        yAxis: {
          type: "value",
        },
        dataZoom: [
          {
            type: "inside",
            start: 90,
            end: 100,
          },
          {
            start: 90,
            end: 100,
          },
        ],
        series: [
          {
            name: "大户账户多空比值",
            data: [120, 200, 150, 80, 70, 110, 130],
            type: "bar",
          },
          {
            name: "大户账户多空比值",
            data: [120, 200, 150, 80, 70, 110, 130],
            type: "bar",
          },
        ],
        graphic: [
          {
            type: "image",
            id: "logo",
            right: "center",
            bottom: "center",
            z: 0,
            bounding: "all",
            style: {
              image:
                "https://apilib.rta.academy/media/2023/07/26/echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
              width: 400,
              height: 68,
            },
          },
        ],
      };
      this.chart.setOption(option);
    },
    //子组件中使用$emit发送事件，val是子组件传递的消息
    changeCurrency(val) {
      this.$emit("changeCurrency", {
        allData: {
          cName: this.currency,
          tradingOn: this.tradingOn,
          period: this.timeSplit,
        },
        type: this.chartType,
      });
    },
    changeTradingOn(val) {
      this.$emit("changeTradingOn", {
        allData: {
          cName: this.currency,
          tradingOn: this.tradingOn,
          period: this.timeSplit,
        },
        type: this.chartType,
      });
    },
    // changeBourse(val) {
    //   this.$emit("changeBourse", {val: val,type: this.chartType});
    // },
    changeTime(val) {
      this.$emit("changeTime", {
        allData: {
          cName: this.currency,
          tradingOn: this.tradingOn,
          period: this.timeSplit,
        },
        type: this.chartType,
      });
    },
  },
};
</script>
<style lang="scss">
.select-container {
  display: flex;
  .chart-select {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    padding-left: 25px !important;
    width: 100%;
    .tittle-pro {
      width: 45px;
      text-align: center;
    }
    .el-select {
      //margin-left: 20px;
      width: 100px;
      .el-input {
        .el-input__inner {
          height: 32px !important;
          background: rgba(159, 159, 159, 0.1) !important;
          border: none;
          border-radius: 4px !important;
          color: #d1d4dc;
          font-size: 13px;
          font-family: MicrosoftYaHei;
        }
        .el-input__inner.is-focus {
          color: #d1d4dc;
        }
        .el-select__caret {
          line-height: 32px;
        }
      }
    }
  }
}
</style>
