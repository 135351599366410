//多空比
import request from "@/utils/request"

//获取Binance大户账户数多空比值、比例、差值
export function sendRequest(data) {
    return request({
        method: 'get',
        url: '/longShortRatio',
        params:data,
    })
}
//获取Binance大户持仓量比值、比例、差值getLargeHoldingsData
export function getLargeHoldingsData(data) {
    return request({
        method: 'get',
        url: '/longShortRatio',
        params:data,
    })
}
//获取Binance账户数多空比值、比例、差值
export function getAccountNumberData(data) {
    return request({
        method: 'get',
        url: '/longShortRatio',
        params:data,
    })
}
//获取Bybit账户数多空比值、比例、差值
export function getBybitAccountNumberData(data) {
    return request({
        method: 'get',
        url: '/longShortRatio',
        params:data,
    })
}